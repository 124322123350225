import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";
import { Icon } from '@types';

const Logo: Icon = ({ fill = "white" }) => {
  return (
    <LogoContainer>
      <svg
        width="200"
        height="50"
        viewBox="0 0 200 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Desktop"
      >
        <style type="text/css">
          @import
          url('https://fonts.googleapis.com/css?family=Overpass&display=swap');
        </style>
        <g id="logo." stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect id="oben." fill={fill} x="0" y="0" width="200" height="5"></rect>
          <rect id="unten." fill={fill} x="5" y="45" width="195" height="5"></rect>
          <rect id="rechts." fill={fill} x="195" y="0" width="5" height="50"></rect>
          <rect id="links." fill={fill} x="0" y="0" width="5" height="50"></rect>
          <text id="nicolai’s-space." fill={fill} fillRule="nonzero" fontFamily="Overpass-Regular, Overpass" fontSize="18" fontWeight="normal" letterSpacing="-0.276923081">
            <tspan x="50" y="30">nicolai’s space.</tspan>
          </text>
        </g>
      </svg>

      <svg
        width="75"
        height="50"
        viewBox="0 0 75 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Mobile"
      >
        <g id="logo-mobile." stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect id="links." fill={fill} x="0" y="0" width="5" height="50"></rect>
          <rect id="oben." fill={fill} x="5" y="0" width="70" height="5"></rect>
          <rect id="unten." fill={fill} x="5" y="45" width="70" height="5"></rect>
          <rect id="rechts" fill={fill} x="70" y="0" width="5" height="50"></rect>
          <text id=":space:" fill={fill} fillRule="nonzero" fontFamily="Overpass-Regular, Overpass" fontSize="18" fontWeight="normal" letterSpacing="-0.276923081">
            <tspan x="15" y="30">:space:</tspan>
          </text>
        </g>
      </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }

    .Logo__Mobile{
      display: block;
    }
  `}
`;
