import React from "react";

import { Icon } from '@types';

const ChevronLeft: Icon = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke={fill} fill={fill} strokeLinecap="round" strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M5 12l14 0"></path>
    <path d="M5 12l6 6"></path>
    <path d="M5 12l6 -6"></path>
  </svg>
);

export default ChevronLeft;
